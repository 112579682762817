<template>
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <router-link :to="{ name: 'company.dashboard' }">
                <i class="uil-home-alt"></i>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.assignments.list' }">
                {{ this.$t('menu.company_dashboard.assignments') }}
              </router-link>
            </li>
            <li>Otomatik Atamalar <!--{{ this.$t('general.all_assignments') }}--></li>
          </ul>
        </nav>
      </div>

      <div class="section-header mb-lg-2 border-0 uk-flex-middle">
        <div class="section-header-left">
          <h2 class="uk-heading-line text-left">
            <span> Tüm Otomatik Atamalar <!--{{ this.$t('general.all_assignments') }}--> ({{ items.total }}) </span>
          </h2>
        </div>
        <div class="section-header-right">
          <router-link :to="{ name: 'company.assignments.addAuto' }">
            <button class="btn btn-default" type="button" aria-expanded="false">
              <i class="uil-location-arrow"></i> Otamatik Atama Yap
              <!--{{ this.$t('menu.company_dashboard.create_assignment')}}-->
            </button>
          </router-link>
        </div>
      </div>

      <template v-if="errorMessages">
        <div v-for="(error, index) in errorMessages" :key="index" class="uk-alert-danger" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color: red">
            <i class="uil-exclamation-triangle"></i> {{ error }}
          </p>
        </div>
      </template>
      <div v-if="successMessage" class="uk-alert-success" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p style="color: green">
          <i class="uil-exclamation-triangle"></i> {{ successMessage }}
        </p>
      </div>
      <div class="card">
        <!-- Card header -->
        <div class="card-header actions-toolbar border-0">
          <div class="d-flex justify-content-between">

            <div class="uk-search uk-search-navbar">
              <i class="uil-search position-absolute"></i>

              <input class="uk-search-input shadow-0 uk-form-small" type="search" v-model="filter.search"
                :placeholder="$t('general.search')" />
              </div>
            <div class="d-flex">
              <!-- Shorting -->
              <a href="#" class="btn btn-icon btn-hover  btn-circle" :uk-tooltip="$t('filters.sort_list')" title=""
                aria-expanded="false">
                <i class="uil-list-ui-alt"></i>
              </a>
              <div id="sortDropDown" uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up"
                class="uk-dropdown">
                <ul class="uk-nav uk-dropdown-nav">
                  <li @click="sortOrder('title', 'asc')">
                    <a href="javascript:void(0)">{{ $t("sorting.a_z") }}</a>
                  </li>
                  <li @click="sortOrder('title', 'desc')">
                    <a href="javascript:void(0)">{{ $t("sorting.z_a") }}</a>
                  </li>
                  <li @click="sortOrder('created_at', 'asc')">
                    <a href="javascript:void(0)">
                      {{ $t("sorting.created_at_asc") }}</a>
                  </li>
                  <li @click="sortOrder('created_at', 'desc')">
                    <a href="javascript:void(0)">
                      {{ $t("sorting.created_at_desc") }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- Table -->
        <template>
          <div class="" v-if="programs_loading">
            <default-loading></default-loading>
          </div>
          <div v-else class="table-responsive">
            <table class="table align-items-center">
              <thead>
                <tr>
                  <th scope="col">{{ 'Atama Adı' }}</th>
                  <th scope="col">{{ $t("general.added_at") }}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody class="list">
                <!--user item-->
                <tr v-for="item in formattedItems">
                  <td>
                    {{ item.name }}
                  </td>
                  <td>{{ item.created_at }}</td>
                  <td class="text-right">
                    <div class="actions ml-3">
                      <router-link :to="{
                        name: 'company.assignments.editAuto',
                        params: { id: item.id },
                      }" class="btn btn-icon btn-hover btn-sm btn-circle" :uk-tooltip="$t('faq_categories.edit')">
                        <i class="uil-edit "></i>
                      </router-link>
                      <a href="javascript:void(0)" class="btn btn-icon btn-hover btn-sm btn-circle" uk-tooltip="Sil"
                        title="" @click="removeItem(item.id)" aria-expanded="false">
                        <i class="uil-minus-circle text-danger"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination v-if="pagination && pagination.hasOwnProperty('links')" :pagination="pagination"
            @changePage="setPage($event)"></pagination>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import DataTable from "@/assets/components/dataTable/DataTable";
import store from "@/core/services";
import Pagination from "@/assets/components/Pagination";
import DefaultLoading from "@/view/components/loading/DefaultLoading";

import module, {
  MODULE_NAME,
  ERROR,
  ITEMS,
  GET_ITEMS,
  DELETE_ITEM,
  LOADING,
  GET_AUTO_ASSIGNMENTS
} from "@/core/services/store/assignment.module";
import moment from "moment";
import Swal from "sweetalert2";
import { RESET_POINTS } from "@/core/services/store/company.module";
export default {
  name: "AssignmentAuto",
  components: { DataTable, Pagination, DefaultLoading },
  data() {
    return {
      pagination: null,
      activeItem: null,
      successMessage: null,
      errorMessages: [],
      page: 1,
      per_page: 10,
      filter: {
        search: null,
        sort: "id",
        order: "desc",
      },
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(MODULE_NAME, module);
  },
  computed: {
    items: {
      get() {
        return store.getters[MODULE_NAME + "/" + ITEMS];
      },
      set(value) { },
    },
    programs_loading: {
      get() {
        return store.getters[MODULE_NAME + "/" + LOADING];
      },
      set(value) { },
    },
    assignment_loading: {
      get() {
        return store.getters[MODULE_NAME + "/" + LOADING];
      },
      set(value) { },
    },
    error: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) { },
    },
    formattedItems() {
      let results = [];
      if (this.items === null || this.items.length === 0) return [];
      this.pagination = this.items;

      this.items.data.forEach((currentItem) => {
        results.push({
          id: currentItem.id,
          name: currentItem.name,
          description: currentItem.description ? currentItem.description : "-",
          total_section: currentItem.sections_count,
          total_content: currentItem.contents_count,
          created_at: currentItem.created_at
            ? moment(currentItem.created_at).format("DD/MM/YYYY HH:mm:ss")
            : "-",
        });
      });

      return results;
    },
  },
  methods: {
    getResult() {
      let self = this;
      // UIkit.dropdown("#sortDropDown").hide();
      this.$store.dispatch(MODULE_NAME + "/" + GET_ITEMS, {
        url: 'api/automatic-assignments',
        filters: {
          like: self.filter.search,
          page: self.page,
          perPage: self.per_page,
          sort: self.filter.sort,
          order: self.filter.order,
        },
      });
    },
    removeItem(id) {
      this.$store
        .dispatch(MODULE_NAME + "/" + DELETE_ITEM, {
          url: 'api/automatic-assignments/' + id,
        })
        .then(() => {
          if (!this.error) {
            this.successMessage = this.$t("general.successfully_deleted");
            this.getResult();
          } else this.errorMessages.push(this.error);
        });
    },
    sortOrder(sort, order, is_user = true) {
      let self = this;
      self.page = 1;
      self.filter.sort = sort;
      self.filter.order = order;

      this.getResult();
    },
    setPage(page, is_user = true) {
      let self = this;
      self.page = page;
      this.getResult();
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
  },
  mounted() {
    this.getResult();
  },
  watch: {
    "filter.search": function (val) {
      this.debouncedFilterSearch();
    },
    successMessage: function (val) {
      this.debouncedResetValues();
    },
    errorMessages: function (val) {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedFilterSearch = _.debounce(this.getResult, 500);
    this.debouncedResetValues = _.debounce(this.resetMessages, 2000);
  },
};
</script>
<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.assignment-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.assignment-order {
  font-size: 12px;
  color: rgb(149, 149, 149);
}
</style>
